const TimezoneJakarta = (datetime) => {
  const utcDate = new Date(datetime);

  // UTC offset untuk Jakarta (7 jam)
  const utcOffset = 7 * 60 * 60 * 1000;

  // Menghitung waktu Jakarta dalam milidetik
  const jakartaTime = utcDate.getTime() + utcOffset;

  // Membuat objek Date untuk waktu Jakarta
  const jakartaDate = new Date(jakartaTime);

  // Mendapatkan jam, menit, dan detik
  const hours = jakartaDate.getUTCHours().toString().padStart(2, "0");
  const minutes = jakartaDate.getUTCMinutes().toString().padStart(2, "0");

  // Format waktu Jakarta sebagai jam:menit:detik
  const formattedJakartaTime = `${hours}:${minutes}`;

  return formattedJakartaTime;
};

export default TimezoneJakarta;
