import { useEffect, useState } from "react";
import { Badge, Modal } from "react-bootstrap";
import Api from "../../../api/Api";
import Loading from "../../../components/molecules/Loading";

const ModalPermissionDetail = ({ id, showModal, setShowModal }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    Api.get(`/leave-details/leaves/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token_suas")}`,
      },
    })
      .then((res) => setData(res.data))
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Status</Modal.Title>
      </Modal.Header>
      {loading ? (
        <Modal.Body>
          <Loading />
        </Modal.Body>
      ) : (
        <Modal.Body>
          {data.map((value, index) => (
            <>
              <div
                className="d-flex align-items-center justify-content-between"
                key={index}
              >
                <div>
                  <div>
                    <b>{value.date}</b>
                  </div>
                  <div className="text-danger">
                    {value.statement !== "-" ? value.statement : null}
                  </div>
                </div>
                <div>
                  <div>
                    {(() => {
                      if (value.status === "pending") {
                        return (
                          <Badge bg="secondary">Diproses {value.pending}</Badge>
                        );
                      }
                      return null;
                    })()}
                  </div>
                  <div>
                    {(() => {
                      if (value.status === "approved") {
                        return (
                          <Badge bg="success">Disetujui {value.approved}</Badge>
                        );
                      }
                      return null;
                    })()}
                  </div>
                  <div>
                    {(() => {
                      if (value.status === "rejected") {
                        return (
                          <Badge bg="danger">Ditolak {value.rejected}</Badge>
                        );
                      }
                      return null;
                    })()}
                  </div>
                </div>
              </div>
              <hr />
            </>
          ))}
        </Modal.Body>
      )}
    </Modal>
  );
};

export default ModalPermissionDetail;
