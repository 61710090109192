import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import Api from "../../../api/Api";
import Gap from "../../../components/atoms/Gap";
import Loading from "../../../components/molecules/Loading";

const ModalEmergencyPermission = ({
  showModalEmergencyPermission,
  setShowModalEmergencyPermission,
}) => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const [statement, setStatement] = useState("");
  const [validation, setValidation] = useState([]);

  const submit = () => {
    setLoading(true);
    const payload = {
      type: "emergency",
      date: new Date(),
      startDate: date,
      endDate: date,
      statement,
    };

    Api.post("/leaves", payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token_suas")}`,
      },
    })
      .then(() => {
        Swal.fire({
          title: "Success",
          text: `berhasil terkirim`,
          icon: "success",
          confirmButtonText: "Tutup",
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      show={showModalEmergencyPermission}
      onHide={() => setShowModalEmergencyPermission(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Pengajuan Izin Mendadak</Modal.Title>
      </Modal.Header>
      {loading ? (
        <Modal.Body>
          <Loading />
        </Modal.Body>
      ) : (
        <Modal.Body>
          <Form onSubmit={submit}>
            <Form.Label htmlFor="inputDate">Tanggal</Form.Label>
            <Form.Control
              type="date"
              id="inputDate"
              aria-describedby="dateHelpBlock"
              placeholder="Masukkan NIP / No. Telp"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
            <Form.Text id="dateHelpBlock" className="text-danger">
              {Array.isArray(validation) &&
                validation.filter((validation) =>
                  validation.includes("date")
                )[0]}
            </Form.Text>
            <Gap size={10} />
            <Form.Label htmlFor="inputStatement">Keterangan</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              id="inputStatement"
              aria-describedby="statementHelpBlock"
              placeholder="Keterangan..."
              value={statement}
              onChange={(e) => setStatement(e.target.value)}
            />
            <Form.Text id="statementHelpBlock" className="text-danger">
              {Array.isArray(validation) &&
                validation.filter((validation) =>
                  validation.includes("statement")
                )[0]}
            </Form.Text>
            <Gap size={30} />
            <div className="d-flex justify-content-center">
              <Button variant="success" onClick={() => submit()}>
                <FontAwesomeIcon icon={faPaperPlane} size="lg" /> Kirim
              </Button>
            </div>
          </Form>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default ModalEmergencyPermission;
