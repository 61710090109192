import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Container,
  Form,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Gap from "../atoms/Gap";

const Topbar = () => {
  const navigate = useNavigate();

  const btnLogout = () => {
    localStorage.removeItem("token_suas");
    navigate("/login", { replace: true });
  };

  return (
    <>
      {/* <Navbar sticky="top" expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Nav onClick={() => navigate("/attendance-history")}>
            <FontAwesomeIcon icon={faClockRotateLeft} size="lg" />
          </Nav>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Absensi</Nav.Link>
              <Nav.Link href="/overtime">Lembur</Nav.Link>
              <Nav.Link href="/permission">Izin</Nav.Link>
              <Nav.Link
                onClick={() => {
                  btnLogout();
                }}
              >
                Keluar
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
      {[false].map((expand) => (
        <Navbar expand={expand} className="bg-body-tertiary">
          <Container fluid>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Brand href="#">
              <a onClick={() => navigate("/attendance-history")}>
                <FontAwesomeIcon icon={faClockRotateLeft} size="lg" />
              </a>
            </Navbar.Brand>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="/">
                    <div className="d-grid gap-2">
                      <Button variant="primary" size="lg">
                        Absensi
                      </Button>
                    </div>
                  </Nav.Link>
                  <Gap size={5} />
                  <Nav.Link href="/overtime">
                    <div className="d-grid gap-2">
                      <Button variant="success" size="lg">
                        Lembur
                      </Button>
                    </div>
                  </Nav.Link>
                  <Gap size={5} />
                  <Nav.Link href="/permission">
                    <div className="d-grid gap-2">
                      <Button variant="warning" size="lg">
                        Izin
                      </Button>
                    </div>
                  </Nav.Link>
                  <Gap size={5} />
                  <Nav.Link>
                    <div className="d-grid gap-2">
                      <Button
                        variant="danger"
                        size="lg"
                        onClick={() => {
                          btnLogout();
                        }}
                      >
                        Keluar
                      </Button>
                    </div>
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default Topbar;
