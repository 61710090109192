import {
  faCamera,
  faPaperPlane,
  faRotateBack,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import Api from "../../../api/Api";
import Gap from "../../../components/atoms/Gap";
import Loading from "../../../components/molecules/Loading";
import CurrentDate from "../../../CurrentDate";
import CurrentTime from "../../../CurrentTime";

const ModalTakePicture = ({
  id,
  showModal,
  setShowModal,
  modalTitle,
  currentTime,
  currentLocation,
  type,
}) => {
  const [loading, setLoading] = useState(false);
  const videoRef = useRef(null);
  const [dateTime, setDateTime] = useState("");
  const [mediaStream, setMediaStream] = useState(null);
  const [capturedImage, setCapturedImage] = useState("");

  useEffect(() => {
    if (showModal) {
      openCamera();
      setDateTime("");
      setMediaStream(null);
      setCapturedImage("");
    } else {
      closeCamera();
    }
  }, [showModal]);

  const openCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setMediaStream(stream);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current
          .play()
          .catch((err) => console.error("Error playing video:", err));
      }
    } catch (err) {
      console.error("Error accessing the camera:", err);
    }
  };

  const closeCamera = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
      setMediaStream(null);
    }
  };

  const takePicture = () => {
    if (videoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      const capturedImg = canvas.toDataURL("image/png");
      setCapturedImage(capturedImg);
      setDateTime(new Date());
      closeCamera();
    }
  };

  const reset = () => {
    setCapturedImage(null);
    openCamera();
  };

  const submit = (type) => {
    setLoading(true);

    if (type === "in") {
      const payload = {
        date: dateTime,
        location: JSON.stringify(currentLocation),
        at: dateTime,
        image: capturedImage,
      };

      Api.post("/attendances/type/regular", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token_suas")}`,
        },
      })
        .then(() => {
          setShowModal(false);
          Swal.fire({
            title: "Success",
            text: `Absen masuk berhasil!\nJam ${CurrentTime(dateTime)}`,
            icon: "success",
            confirmButtonText: "Tutup",
          }).then(() => {
            window.location.reload();
          });
        })
        .catch(() => {
          Swal.fire({
            title: "Error",
            text: "Absen masuk gagal!",
            icon: "error",
            confirmButtonText: "Tutup",
          }).then(() => {
            window.location.reload();
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (type === "out") {
      const payload = {
        attendanceId: id,
        location: JSON.stringify(currentLocation),
        at: dateTime,
        image: capturedImage,
      };

      Api.post(`/attendance-details`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token_suas")}`,
        },
      })
        .then(() => {
          setShowModal(false);
          Swal.fire({
            title: "Success",
            text: `Absen keluar berhasil!\nJam ${CurrentTime(dateTime)}`,
            icon: "success",
            confirmButtonText: "Tutup",
          }).then(() => {
            window.location.reload();
          });
        })
        .catch(() => {
          Swal.fire({
            title: "Error",
            text: "Absen keluar gagal!",
            icon: "error",
            confirmButtonText: "Tutup",
          }).then(() => {
            window.location.reload();
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      {loading ? (
        <Modal.Body>
          <Loading />
        </Modal.Body>
      ) : (
        <Modal.Body>
          {!capturedImage ? (
            <>
              <video
                ref={videoRef}
                style={{
                  width: "100%",
                  objectFit: "cover",
                  display: capturedImage ? "none" : "block",
                }}
              />
              <Gap size={10} />
              <div className="text-center">{CurrentDate()}</div>
              <div className="text-center">{currentTime}</div>
              <Gap size={10} />
              <div className="text-center">
                <Button variant="secondary" onClick={() => takePicture()}>
                  <FontAwesomeIcon icon={faCamera} size="lg" /> Ambil Foto
                </Button>
              </div>
            </>
          ) : (
            <>
              <img src={capturedImage} style={{ width: "100%" }} />
              <Gap size={10} />
              <div className="text-center">{CurrentDate(dateTime)}</div>
              <div className="text-center">{CurrentTime(dateTime)}</div>
              <Gap size={10} />
              <div className="d-flex justify-content-center">
                <Button variant="secondary" onClick={() => reset()}>
                  <FontAwesomeIcon icon={faRotateBack} size="lg" /> Ulangi
                </Button>
                <Gap size={5} />
                <Button variant="success" onClick={() => submit(type)}>
                  <FontAwesomeIcon icon={faPaperPlane} size="lg" /> Kirim
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      )}
    </Modal>
  );
};

export default ModalTakePicture;
