const CurrentDate = (dateTime = null) => {
  if (dateTime) {
    let today = dateTime;

    let date = today.getDate();
    let month = today.getMonth();
    let year = today.getFullYear();

    const monthNames = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    let monthName = monthNames[month];
    return `${date} ${monthName} ${year}`;
  } else {
    let today = new Date();

    let date = today.getDate();
    let month = today.getMonth();
    let year = today.getFullYear();

    const monthNames = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    let monthName = monthNames[month];
    return `${date} ${monthName} ${year}`;
  }
};

export default CurrentDate;
