import { useEffect, useState } from "react";
import { Badge, Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Api from "../../../api/Api";
import Gap from "../../../components/atoms/Gap";
import Loading from "../../../components/molecules/Loading";
import FormatDate from "../../../FormatDate";
import ModalPermissionDetail from "../components/ModalPermissionDetail";

const StatusPermission = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [year, setYear] = useState("2024");
  const [month, setMonth] = useState("");
  const [id, setId] = useState("");
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    Api.get("/leaves", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token_suas")}`,
      },
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Container>
        <Gap size={10} />
        <div className="text-center" style={{ fontSize: 18 }}>
          <b>Status Izin</b>
        </div>
        <Gap size={10} />
        <div className="d-flex justify-content-center">
          <div>
            <Form.Select
              aria-label="list year select"
              value={year}
              onChange={(e) => {
                setYear(e.target.value);
                setMonth("");
              }}
            >
              <option value="" disabled>
                Pilih Tahun
              </option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
            </Form.Select>
          </div>
          <Gap size={20} />
          <div>
            <Form.Select
              aria-label="list month select"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
            >
              <option value="" disabled>
                Pilih Bulan
              </option>
              <option value="1">Januari</option>
              <option value="2">Februari</option>
              <option value="3">Maret</option>
              <option value="4">April</option>
              <option value="5">Mei</option>
              <option value="6">Juni</option>
              <option value="7">Juli</option>
              <option value="8">Agustus</option>
              <option value="9">September</option>
              <option value="10">Oktober</option>
              <option value="11">November</option>
              <option value="12">Desember</option>
            </Form.Select>
          </div>
        </div>
        <Gap size={20} />
        {data.map((value, index) => (
          <>
            <div
              className="d-flex align-items-center justify-content-between"
              key={index}
              onClick={() => {
                setId(value.id);
                setShowModal(true);
              }}
            >
              <div>
                <div>
                  <b>
                    {(() => {
                      if (value.type === "personal") return "Personal";
                      if (value.type === "sick") return "Sakit";
                      if (value.type === "emergency") return "Darurat";
                      if (value.type === "absence") return "Cuti";
                      return null;
                    })()}
                  </b>
                </div>
                <div>{value.statement}</div>
                <div>
                  {value.startDate !== value.endDate
                    ? `${FormatDate(value.startDate)} - ${FormatDate(
                        value.endDate
                      )}`
                    : FormatDate(value.startDate)}
                </div>
              </div>
              <div>
                <div>
                  {(() => {
                    if (value.pending > 0) {
                      return (
                        <Badge bg="secondary">Diproses {value.pending}</Badge>
                      );
                    }
                    return null;
                  })()}
                </div>
                <div>
                  {(() => {
                    if (value.approved > 0) {
                      return (
                        <Badge bg="success">Disetujui {value.approved}</Badge>
                      );
                    }
                    return null;
                  })()}
                </div>
                <div>
                  {(() => {
                    if (value.rejected > 0) {
                      return (
                        <Badge bg="danger">Ditolak {value.rejected}</Badge>
                      );
                    }
                    return null;
                  })()}
                </div>
              </div>
            </div>
            <hr />
          </>
        ))}
      </Container>
      <ModalPermissionDetail
        id={id}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default StatusPermission;
