import { createBrowserRouter } from "react-router-dom";
import AttendanceHistory from "../pages/attendance-history";
import Login from "../pages/login";
import Main from "../pages/main";
import Overtime from "../pages/overtime";
import Permission from "../pages/permission";
import StatusPermission from "../pages/permission/status";
import Root from "./Root";

const router = createBrowserRouter([
  {
    path: "",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Main />,
      },
      {
        path: "/overtime",
        element: <Overtime />,
      },
      {
        path: "/attendance-history",
        element: <AttendanceHistory />,
      },
      {
        path: "/permission",
        element: <Permission />,
      },
      {
        path: "/permission/status",
        element: <StatusPermission />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

export default router;
