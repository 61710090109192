import { useEffect, useState } from "react";
import { Badge, Container, Form } from "react-bootstrap";
import Api from "../../api/Api";
import Gap from "../../components/atoms/Gap";
import Loading from "../../components/molecules/Loading";
import FormatDate from "../../FormatDate";
import TimezoneJakarta from "../../TimezoneJakarta";

const AttendanceHistory = () => {
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState("2024");
  const [month, setMonth] = useState("");
  const [data, setData] = useState([]);
  const [type, setType] = useState("regular");

  useEffect(() => {
    Api.get(`/attendances/type/${type}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token_suas")}`,
      },
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChangeType = (value) => {
    setLoading(true);
    Api.get(`/attendances/type/${value}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token_suas")}`,
      },
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeMonth = (value) => {
    setLoading(true);
    Api.get(`/attendances/type/${type}?ym=${year}-${value}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token_suas")}`,
      },
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function calculateHourDifference(date1, date2) {
    const differenceMs = Math.abs(date2 - date1);
    const hours = differenceMs / (1000 * 60 * 60);
    return hours.toFixed(2);
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Gap size={10} />
      <div className="text-center" style={{ fontSize: 18 }}></div>
      <div className="d-flex align-items-center justify-content-center">
        <b>Riwayat Kehadiran</b>
        <Gap size={10} />
        <div>
          <Form.Select
            aria-label="list type"
            value={type}
            onChange={(e) => {
              setType(e.target.value);
              handleChangeType(e.target.value);
            }}
          >
            <option value="" disabled>
              Pilih Tipe
            </option>
            <option value="regular">Reguler</option>
            <option value="overtime">Lembur</option>
          </Form.Select>
        </div>
      </div>
      <Gap size={10} />
      <div className="d-flex justify-content-center">
        <div>
          <Form.Select
            aria-label="list year select"
            value={year}
            onChange={(e) => {
              setYear(e.target.value);
              setMonth("");
            }}
          >
            <option value="" disabled>
              Pilih Tahun
            </option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
          </Form.Select>
        </div>
        <Gap size={20} />
        <div>
          <Form.Select
            aria-label="list month select"
            value={month}
            onChange={(e) => {
              setMonth(e.target.value);
              handleChangeMonth(e.target.value);
            }}
          >
            <option value="" disabled>
              Pilih Bulan
            </option>
            <option value="01">Januari</option>
            <option value="02">Februari</option>
            <option value="03">Maret</option>
            <option value="04">April</option>
            <option value="05">Mei</option>
            <option value="06">Juni</option>
            <option value="07">Juli</option>
            <option value="08">Agustus</option>
            <option value="09">September</option>
            <option value="10">Oktober</option>
            <option value="11">November</option>
            <option value="12">Desember</option>
          </Form.Select>
        </div>
      </div>
      <hr />
      {data.map((value, index) =>
        value.type === "leave" ? (
          <>
            <div
              className="d-flex align-items-center justify-content-between"
              key={index}
            >
              <div>{FormatDate(value.date)}</div>
              <div>
                <Badge bg="danger">Izin</Badge>
              </div>
            </div>
            <hr />
          </>
        ) : (
          <>
            <div
              className="d-flex align-items-center justify-content-between"
              key={index}
            >
              <div>
                <div>{FormatDate(value.date)}</div>
                <div>
                  {TimezoneJakarta(value.attendanceDetails[0]?.at)} -{" "}
                  {TimezoneJakarta(value.attendanceDetails[1]?.at)}
                </div>
              </div>
              <div>
                {calculateHourDifference(
                  new Date(value.attendanceDetails[0]?.at),
                  new Date(value.attendanceDetails[1]?.at)
                ) >= 8 ? (
                  <Badge bg="success">Memenuhi</Badge>
                ) : (
                  <Badge bg={value.type === "overtime" ? "success" : "danger"}>
                    {calculateHourDifference(
                      new Date(value.attendanceDetails[0]?.at),
                      new Date(value.attendanceDetails[1]?.at)
                    )}{" "}
                    Jam
                  </Badge>
                )}
              </div>
            </div>
            <hr />
          </>
        )
      )}
    </Container>
  );
};

export default AttendanceHistory;
