import {
  faCancel,
  faCheck,
  faDoorClosed,
  faDoorOpen,
  faLocationDot,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import L from "leaflet";
import { useEffect, useRef, useState } from "react";
import { Badge, Button, Container, Image, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Api from "../../api/Api";
import Gap from "../../components/atoms/Gap";
import Loading from "../../components/molecules/Loading";
import CurrentDate from "../../CurrentDate";
import CurrentTime from "../../CurrentTime";
import ModalTakePicture from "./components/ModalTakePicture";

const Overtime = () => {
  const profile = JSON.parse(localStorage.getItem("profile_suas"));

  const [showModalWarning, setShowModalWarning] = useState(true);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState("");
  const [isIn, setIsIn] = useState(false);
  const [isOut, setIsOut] = useState(false);
  const [totalHour, setTotalHour] = useState(0);
  const [currentTime, setCurrentTime] = useState("");
  const mapRef = useRef(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [insideRadius, setInsideRadius] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [type, setType] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(CurrentTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    Api.get("/attendances/type/overtime/latest", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token_suas")}`,
      },
    })
      .then((res) => {
        const latestAttendanceDetail = res.data.attendanceDetails.sort(
          (a, b) => new Date(b.at) - new Date(a.at)
        )[0];

        if (latestAttendanceDetail.type === "in") {
          setId(res.data.id);
          setIsIn(false);
          setIsOut(true);
          setType("out");
          const startTime = new Date(latestAttendanceDetail.at);
          const currentTime = new Date();
          const timeDifference = currentTime - startTime;
          const hoursDifference = timeDifference / (1000 * 60 * 60);
          setTotalHour(hoursDifference);
        }

        if (latestAttendanceDetail.type === "out") {
          setIsIn(true);
          setIsOut(false);
          setType("in");
        }
      })
      .catch((err) => {
        if (err.response.data.statusCode === 404) {
          setIsIn(true);
          setIsOut(false);
          setType("in");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const circleCenter = L.latLng(-8.1742591, 113.7819429);
  const circleRadius = 200;

  useEffect(() => {
    if (!mapRef.current) {
      const map = L.map("map", {
        center: [-8.1742591, 113.7819429],
        zoom: 16,
      });

      L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);

      var redIcon = L.icon({
        iconUrl:
          "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });

      L.marker([-8.1742591, 113.7819429], { icon: redIcon })
        .bindPopup("<b>PT. Sumber Urip Agri Satwa</b>")
        .addTo(map);

      L.circle([-8.1742591, 113.7819429], {
        color: "red",
        fillColor: "#f03",
        fillOpacity: 0.2,
        radius: circleRadius,
      }).addTo(map);

      mapRef.current = map;
    }
  }, []);

  const getMyLocation = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const userLocation = L.latLng(latitude, longitude);
          setCurrentLocation(position.coords);

          const map = mapRef.current;
          if (map) {
            map.setView([latitude, longitude], 17);
            L.marker([latitude, longitude])
              .addTo(map)
              .bindPopup("Lokasi anda saat ini!")
              .openPopup();

            const distanceToCircleCenter =
              circleCenter.distanceTo(userLocation);

            setInsideRadius(distanceToCircleCenter <= circleRadius);
          }

          setLoading(false);
        },
        (error) => {
          console.error("Error getting geolocation:", error);
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "Geolocation is not supported by this browser.",
        icon: "error",
        confirmButtonText: "Tutup",
      });
    }
  };

  const presence = (type) => {
    // if (!currentLocation) {
    //   Swal.fire({
    //     title: "Error",
    //     text: "Sinkronisasi terlebih dahulu",
    //     icon: "error",
    //     confirmButtonText: "Tutup",
    //   });
    //   return;
    // } else {
    //   if (type === "in" && insideRadius) {
    //     setShowModal(true);
    //     setModalTitle("Lembur Masuk");
    //   } else if (type === "out" && insideRadius) {
    //     setShowModal(true);
    //     setModalTitle("Lembur Keluar");
    //   } else {
    //     Swal.fire({
    //       title: "Error",
    //       text: "❌ Anda berada di luar jangkauan",
    //       icon: "error",
    //       confirmButtonText: "Tutup",
    //     });
    //   }
    // }

    if (!currentLocation) {
      Swal.fire({
        title: "Error",
        text: "Sinkronisasi terlebih dahulu",
        icon: "error",
        confirmButtonText: "Tutup",
      });
      return;
    } else {
      if (type === "in") {
        setShowModal(true);
        setModalTitle("Lembur Masuk");
      } else if (type === "out") {
        setShowModal(true);
        setModalTitle("Lembur Keluar");
      } else {
        Swal.fire({
          title: "Error",
          text: "❌ Anda berada di luar jangkauan",
          icon: "error",
          confirmButtonText: "Tutup",
        });
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <div className="d-flex align-items-center justify-content-between">
            <div
              className="d-flex align-items-center"
              style={{ width: "100%" }}
            >
              <Image src="no_photo.jpg" width={100} height={100} />
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ width: "100%" }}
              >
                <div>
                  <div>
                    <b>{profile?.name}</b>
                  </div>
                  <div>{profile?.nip}</div>
                  <div>{profile?.department?.name}</div>
                </div>
                <div>
                  <Badge pill bg="success" style={{ fontSize: 15 }}>
                    Lembur
                  </Badge>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <div>
              {CurrentDate()} <b>{currentTime}</b>
            </div>
          </div>
          <hr style={{ margin: 5 }} />
          <div className="text-center">
            <div className="text-success">
              Anda bekerja lembur <b>{totalHour.toFixed(1)}</b> jam
            </div>
          </div>
          <div className="text-center">
            {(() => {
              if (!insideRadius && !currentLocation)
                return (
                  <div className="text-danger">
                    <FontAwesomeIcon icon={faWarning} />{" "}
                    <b>Belum sinkronisasi</b>
                  </div>
                );
              if (insideRadius && currentLocation)
                return (
                  <div className="text-success">
                    <FontAwesomeIcon icon={faCheck} />{" "}
                    <b>Anda berada didalam jangkauan</b>
                  </div>
                );
              if (!insideRadius && currentLocation)
                return (
                  <div className="text-danger">
                    <FontAwesomeIcon icon={faCancel} />{" "}
                    <b>Anda berada diluar jangkauan</b>
                  </div>
                );
            })()}
          </div>
          <Gap size={10} />
          <div className="d-flex justify-content-center">
            <Button
              variant="success"
              onClick={() => {
                presence(type);
              }}
              size="lg"
              disabled={!isIn}
            >
              <FontAwesomeIcon icon={faDoorClosed} /> Masuk
            </Button>
            <Gap size={10} />
            <Button variant="warning" onClick={getMyLocation} size="lg">
              <FontAwesomeIcon icon={faLocationDot} /> Sync
            </Button>
            <Gap size={10} />
            <Button
              variant="danger"
              onClick={() => {
                presence(type);
              }}
              size="lg"
              disabled={!isOut}
            >
              <FontAwesomeIcon icon={faDoorOpen} /> Keluar
            </Button>
          </div>

          <Gap size={10} />
        </Container>
      )}
      <div id="map" style={{ width: "100%", height: "350px" }}></div>
      <ModalTakePicture
        id={id}
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle={modalTitle}
        currentTime={currentTime}
        currentLocation={currentLocation}
        type={type}
      />
      <Modal
        show={showModalWarning}
        onHide={() => navigate("/", { replace: true })}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Peringatan!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-danger">
            Syarat boleh lembur adalah telah bekerja regular penuh hari ini dan
            intruksi dari manajemen terimakasih!
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              navigate("/", { replace: true });
            }}
          >
            Kembali
          </Button>
          <Button
            variant="success"
            onClick={() => {
              setShowModalWarning(false);
            }}
          >
            Lanjutkan
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Overtime;
