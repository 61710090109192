import { useEffect, useState } from "react";
import { Badge, Button, Card, CardBody, Container } from "react-bootstrap";
import Gap from "../../components/atoms/Gap";
import ModalEmergencyPermission from "./components/ModalEmergencyPermission";
import ModalPermission from "./components/ModalPermission";
import { useNavigate } from "react-router-dom";
import Api from "../../api/Api";
import Loading from "../../components/molecules/Loading";
import Swal from "sweetalert2";

const Permission = () => {
  const [showModalPermission, setShowModalPermission] = useState(false);
  const [showModalEmergencyPermission, setShowModalEmergencyPermission] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [totalLeave, setTotalLeave] = useState(0);
  const [emergencyLeave, setEmergencyLeave] = useState(false);
  const [lastEmergencyLeave, setLastEmergencyLeave] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    Api.get("/leave-details/total-leave-by-month", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token_suas")}`,
      },
    })
      .then((res) => {
        setTotalLeave(res.data);

        Api.get("/leave-details/total-emergency-leave-by-month", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token_suas")}`,
          },
        })
          .then((res) => {
            if (res.data === 0) {
              setEmergencyLeave(true);
            } else {
              setEmergencyLeave(false);
            }

            Api.get("/leave-details/last-emergency-leave-by-month", {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token_suas")}`,
              },
            })
              .then((res) => {
                setLastEmergencyLeave(res.data.date);
              })
              .catch((error) => {
                console.error(error);
              });
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .finally(() => {
        setLoading(false);
      });
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div>
        <Gap size={20} />
        <Container>
          <table>
            <tbody>
              <tr>
                <td>Jumlah Izin bulan ini</td>
                <td style={{ paddingLeft: 8, paddingRight: 8 }}>:</td>
                <td>
                  <b>{totalLeave}</b>
                </td>
              </tr>
              <tr>
                <td>Izin mendadak</td>
                <td style={{ paddingLeft: 8, paddingRight: 8 }}>:</td>
                <td>
                  {emergencyLeave ? (
                    <Badge pill bg="success" style={{ fontSize: 14 }}>
                      Boleh
                    </Badge>
                  ) : (
                    <Badge pill bg="danger" style={{ fontSize: 14 }}>
                      Tidak Boleh
                    </Badge>
                  )}
                </td>
              </tr>
              <tr className="text-danger">
                <td>Terakhir Izin mendadak</td>
                <td style={{ paddingLeft: 8, paddingRight: 8 }}>:</td>
                <td>
                  <b>{lastEmergencyLeave ? lastEmergencyLeave : "-"}</b>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <Gap size={15} />
          <div className="d-grid gap-2">
            <Button
              variant="warning"
              size="lg"
              onClick={() => {
                setShowModalPermission(true);
              }}
            >
              Pengajuan Izin
            </Button>
          </div>
          <Gap size={25} />
          <div className="d-grid gap-2">
            <Button
              variant="danger"
              size="lg"
              onClick={() => {
                if (emergencyLeave) {
                  setShowModalEmergencyPermission(true);
                } else {
                  Swal.fire({
                    title: "Error",
                    text: "Tidak diperbolehkan",
                    icon: "error",
                    confirmButtonText: "Tutup",
                  });
                }
              }}
            >
              Pengajuan Izin Mendadak
            </Button>
          </div>
          <Gap size={25} />
          <div className="d-grid gap-2">
            <Button
              variant="primary"
              size="lg"
              onClick={() => {
                navigate("/permission/status");
              }}
            >
              Status Pengajuan Izin
            </Button>
          </div>
        </Container>
      </div>
      <ModalPermission
        showModalPermission={showModalPermission}
        setShowModalPermission={setShowModalPermission}
      />
      <ModalEmergencyPermission
        showModalEmergencyPermission={showModalEmergencyPermission}
        setShowModalEmergencyPermission={setShowModalEmergencyPermission}
      />
    </>
  );
};

export default Permission;
